import { FormattedMessage, MessageDescriptor } from 'react-intl';

/**
 * This function provides proper translations based on environment using the provided message object.
 *
 * @param {string} id - The ID of the message to translate.
 * @param {boolean} isString - The isString helps to return string value instead of JSX.Element
 * @param {object} [messagesFromCOS={}] - An object containing messaes fetched from COS (messages.ts).
 * @param {object} [messagesFromAEM={}] - An object containing messages fetched from AEM.
 * @returns {string | JSX.Element} - The translated message.
 */

export type TranslationFunction = (
  id: string,
  isString?: boolean,
) => string | JSX.Element;

interface Messages {
  [key: string]: string;
}

export const createUseTranslation = (
  messages: Record<string, MessageDescriptor>,
  messagesFromAEM: Messages,
): TranslationFunction => {
  const useTranslation: TranslationFunction = (id, isString) => {
    const isEmpty = (obj: Messages) => Object.keys(obj).length === 0;

    if (isEmpty(messagesFromAEM)) {
      if (isString) {
        return messages[id].defaultMessage as string;
      } else {
        return <FormattedMessage {...messages[id]} />;
      }
    } else {
      return messagesFromAEM[id];
    }
  };

  return useTranslation;
};
